import axios from "axios";
import { AXIOS } from "../../../axios";
import ls from "localstorage-slim";
import router from "../../../router";
import env from "@/env";
import {
  successToast,
  warningToast,
  errorToast,
} from "@/helpers/toastifications";

import {
  storeCookies,
  getCookies,
  deleteCookie,
} from "@/helpers/store-cookies.js";

ls.config.encrypt = true;

// staging baseUrl
// const BaseURL = '/';

// production baseUrl
const BaseURL = env.baseURL;

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem("user_data") || "",
    token: getCookies("access_token") || "",
    isLogged: false,
    days: "",
    loading: false,
    industrial_types: [],
    invalidOtp: false,
    emailExistMsg: "",
    loginLoading: false,
    playType: false,
    currencies: [],
    role: "",
    permissions: {},
    main_currency: JSON.parse(localStorage.getItem("cur_data")),
    currency: {},
  },
  getters: {
    // getUser: state => JSON.parse(state.user),
    isLogged: (state) => state.isLogged,
    isAuthenticated: (state) => state.token,
    days: (state) => state.days,
    loading: (state) => state.loading,
    industrial_types: (state) => state.industrial_types,
    invalidOtp: (state) => state.invalidOtp,
    emailExistMsg: (state) => state.emailExistMsg,
    loginLoading: (state) => state.loginLoading,
    playType: (state) => state.playType,
    currencies: (state) => state.currencies,
    role: (state) => state.role,
    permissions: (state) => state.permissions,
    currency: (state) => state.currency,
  },
  mutations: {
    loginMutation: (state) => {
      state.isLogged = true;
    },

    logoutMutation: (state) => {
      state.isLogged = false;
    },

    set_days: (state, data) => {
      state.days = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_industrial_types: (state, data) => {
      state.industrial_types = data;
    },

    set_invalidOtp: (state, data) => {
      state.invalidOtp = data;
    },

    set_emailExistMsg: (state, data) => {
      state.emailExistMsg = data;
    },
    set_userSubscriptions: (state, data) => {
      state.userSubscriptions = data;
    },
  

    set_loginLoading: (state, data) => {
      state.loginLoading = data;
    },

    set_playType: (state, data) => {
      state.playType = data;
    },

    set_currencies: (state, data) => {
      state.currencies = data;
    },

    set_role: (state, data) => {
      state.role = data;
    },

    set_permissions: (state, data) => {
      state.permissions = data;
    },
    set_currency: (state, data) => {
      state.currency = data;
    },
  },
  actions: {
    loginAction: async ({ commit, rootState, dispatch, state }, payload) => {
      localStorage.removeItem("companyLogo");
      localStorage.removeItem("companyName");
      localStorage.removeItem("FullName");
      localStorage.removeItem("xtenant");
      localStorage.removeItem("email");
      localStorage.removeItem("company_address");
      ls.remove("per");
      ls.remove("randomID");
      ls.remove("code");
      ls.remove("perToken");
      ls.remove("curr_name");
      ls.remove("symbol");
      commit("set_loginLoading", true);
      try {
        const res = await axios.post(`${BaseURL}api/Login`, {
            email: payload.user.email,
            password: payload.user.password,
            ...(payload.user.tenant_id && { tenant_id: payload.user.tenant_id })
        });
        if (res.status === 200) {
          if (res.data.UserData.message === "Unauthorized") {
            warningToast("Email or Password is not correct!");
            commit("set_loginLoading", false);
          } else {
            localStorage.setItem("xtenant", res.data.GetTenantID);
            localStorage.setItem("isActive", res.data.isActive);
            ls.set("randomID", res.data.DaysTrail);
            localStorage.setItem("codeee", res.data.code);
            ls.set("code", res.data.code);

            ls.set("SubscriptionStatus", res.data.SubscriptionStatus);

            commit("set_days", res.data.TrialDays);
            storeCookies("access_token", res.data.UserData.accessToken);
            commit("loginMutation");
            localStorage.setItem("FullName", res.data.FullName);
            localStorage.setItem("layout", "dashboard-layout");
            localStorage.setItem("email", res.data.UserData.user.Email);
            localStorage.setItem("tierId", res.data.tier);
            localStorage.setItem("role", res.data.UserData.user.Role);
            localStorage.setItem("locale", "en");
            const successData = {
              full_name: res.data.FullName ,
              email: res.data.UserData.user.Email ,
              mobile_number: res.data.Phone ,
              plan_id : res.data.tier
            }
            console.log("goToSub" , successData)
            commit("set_userSubscriptions", successData);

            localStorage.setItem(
              "company_address",
              res.data.UserData.user.CompanyBillingSettings
            );

            if (res.data.UserData.user.CompanyLogo) {
              localStorage.setItem(
                "companyLogo",
                `${
                  res.data.UserData.user.CompanyLogo
                }?XTenant=${localStorage.getItem("xtenant")}`
              );
            }

            localStorage.setItem(
              "companyName",
              res.data.UserData.user.CompanyName
            );
            rootState.companyLogo = `${
              res.data.UserData.user.CompanyLogo
            }?XTenant=${localStorage.getItem("xtenant")}`;
            rootState.companyName = res.data.UserData.user.CompanyName;

            ls.set("perToken", res.data.UserData.user.Role);

            dispatch(
              "dashboard/set_currency_data_action",
              res.data.CurrencyData
            );

            localStorage.setItem(
              "cur_data",
              JSON.stringify(res.data.CurrencyData)
            );

            if (res.data.UserData.user.Role == "PM") {
              window.location.replace("/projects");
            } else if (res.data.UserData.user.Role == "reg_user") {
              window.location.replace("/sales");
            } else if (
              res.data.UserData.user.Role == "admin" ||
              res.data.UserData.user.Role == "accountant"
            ) {
              const canLogin = ['active', 'intrial' ,'pausing', 'canceling']; // Array of statuses allowing login

              const goToSub =  ['waiting' , 'canceled']
              if(goToSub.includes(res.data.SubscriptionStatus.toLowerCase())){

                window.location.replace("/subscription");

              }
              else if (res.data?.allowSubscription === false || res?.data?.SubscriptionStatus === undefined){
                window.location.replace("/dashboard");
              }
              else if (canLogin.includes(res.data.SubscriptionStatus.toLowerCase())){
                window.location.replace("/dashboard");
              }

            }
          }
        }
      } catch (error) {
        error.message;
        if (error.response.status === 409) {
          warningToast(error.response.data.message);
        } else {
          errorToast("Something went wrong!");
        }
        commit("set_loginLoading", false);
      }
    },

    logoutAction: ({ commit }) => {
      localStorage.clear();
      localStorage.removeItem("xtenant");
      deleteCookie("access_token");
      localStorage.setItem("layout", "landing-layout");
      localStorage.removeItem("companyLogo");
      localStorage.removeItem("companyName");
      localStorage.removeItem("FullName");
      localStorage.removeItem("AccessAccounts");
      localStorage.removeItem("email");
      localStorage.removeItem("company_address");
      ls.set("randomID", null);
      delete AXIOS.defaults.headers.common["Authorization"];
      commit("logoutMutation");
      window.location.replace("/");
    },
    
    registerAction: async ({ commit }, payload) => {

      commit("set_loading", true);
      return axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: payload.industrial_type,
          company_size: payload.company_size,
          mobile_number: payload.phone,
          tier_id: payload.teir_id,
          currency_id: payload.currency,
          first_name: payload.firstName,
          last_name: payload.lastName,
        })
        .then((res) => {
   
          let response = res.data;
          let objectRes = response
          const successData = {
            full_name: `${payload.firstName} ${payload.lastName}` ,
            email: payload.email ,
            mobile_number: payload.phone ,
            plan_id : payload.teir_id 
          }
          
                    commit("set_userSubscriptions", successData);
  
                    if (res.status == 200) {
                      
                      if (objectRes.UserData.message != null) {
                        warningToast("Email or Password is not correct!");
                      } else if(objectRes?.allowSubscription == true) {
          
                      router.push({ path: `/subscription` });
          
                      }else{
                        router.push({ path: `/thank-you` });
                      }
                      commit("set_loginLoading", false);
          
                      
                    }
                    return res
        })
        .catch((error) => {
          
   
  
          if (error?.response?.status === 409) {
            commit("set_loading", false);
            return warningToast(error.response.data.message);
          }
          commit("set_loading", false);
          localStorage.setItem("otp_done", false);
        });
    },
    registerActionNew: async ({ commit }, payload) => {
  
      commit("set_loading", true);
      return axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: payload.industrial_type,
          company_size: payload.company_size,
          mobile_number: payload.phone,
          tier_id: payload.teir_id,
          currency_id: payload.currency,
          first_name: payload.firstName,
          last_name: payload.lastName,
        })
        .then((res) => {
           let response = res.data;
          let objectRes = response
          const successData = {
            full_name: `${payload.firstName} ${payload.lastName}` ,
            email: payload.email ,
            mobile_number: payload.phone ,
            plan_id : payload.teir_id 
          }
          
                    commit("set_userSubscriptions", successData);
  
                    if (res.status == 200) {
                      
                      if (objectRes.UserData.message != null) {
                        warningToast("Email or Password is not correct!");
                      } else if(objectRes?.allowSubscription == true) {
          
                      router.push({ path: `/subscription` });
          
                      }else{
                        router.push({ path: `/thank-you` });
  
                      }
                      commit("set_loginLoading", false);
          
                      
                    }
                    return res
        })
        .catch((error) => {
          
   
          if (error?.response?.status === 409) {
            commit("set_loading", false);
            return warningToast(error.response.data.message);
          }
          commit("set_loading", false);
          localStorage.setItem("otp_done", false);
        });
    },
    registerActionEvent: async ({ commit }, payload) => {
  
      commit("set_loading", true);
      return axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: payload.industrial_type,
          company_size: payload.company_size,
          mobile_number: payload.phone,
          tier_id: payload.teir_id,
          currency_id: payload.currency,
          first_name: payload.firstName,
          last_name: payload.lastName,
        })
        .then((res) => {
           let response = res.data;
          let objectRes = response
          const successData = {
            full_name: `${payload.firstName} ${payload.lastName}` ,
            email: payload.email ,
            mobile_number: payload.phone ,
            plan_id : payload.teir_id 
          }
          
                    commit("set_userSubscriptions", successData);
  
                    if (res.status == 200) {
                      
                      if (objectRes.UserData.message != null) {
                        warningToast("Email or Password is not correct!");
                      } else if(objectRes?.allowSubscription == true) {
          
                      router.push({ path: `/subscription` });
          
                      }else{
                        router.push({ path: `/thank-you` });
  
                      }
                      commit("set_loginLoading", false);
          
                      
                    }
                    return res
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            commit("set_loading", false);
            return warningToast(error.response.data.message);
          }
          commit("set_loading", false);
          localStorage.setItem("otp_done", false);
        });
    },

    get_industrial_types_action: ({ commit }) => {
      axios
        .get(`${BaseURL}api/GetAllIndustties`)
        .then((res) => {
          commit("set_industrial_types", res.data.Industry);
        })
        .catch((err) => {
          err.message;
        });
    },

    verify_otp_action: ({ commit }, payload) => {
      axios
        .post(
          `${BaseURL}Auth/VerifyOtp`,
          {
            otp: payload.otp,
          },
          {
            headers: {
              XTenant: `${localStorage.getItem("xtenant")}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            router.push({ path: `/login` });
            // Vue.$toast.success('Validation success', {
            //     timeout: 5000
            // })
            successToast("Validation success");
            commit("set_invalidOtp", false);
            localStorage.setItem("invalidOtp", false);
          }
        })
        .catch((error) => {
          error.message;
          if (error.response.status == 422) {
            // Vue.$toast.warning(error.response.data.message, {
            //     timeout: 5000
            // })
            warningToast(error.response.data.message);
            commit("set_invalidOtp", true);
            localStorage.setItem("invalidOtp", true);
          }
        });
    },

    resend_otp_action: ({ commit }) => {
      axios
        .post(`${BaseURL}Auth/RequestOtp`, {
          email: localStorage.getItem("TenantEmail"),
        })
        .then((res) => {})
        .catch((err) => {
          err.message;
        });
    },

    setEmailExistMsgAction: ({ commit }, data) => {
      commit("set_emailExistMsg", data);
    },

    get_currencies_action: ({ commit, state }) => {
      axios
        .get(`${BaseURL}api/AllCurrencies`)
        .then((res) => {
          commit("set_currencies", res.data);
          commit(
            "set_currency",
            state.currencies[state.currencies.length - 72]
          );
        })
        .catch((err) => {
          err.message;
        });
    },

    event_submit_action: ({ commit }, payload) => {
      commit("set_loading", true);
      axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: 1,
          company_size: "5-11",
          mobile_number: payload.phone,
          tier_id: 2,
          currency_id: 30,
          first_name: payload.firstName,
          last_name: payload.lastName,
          user_type: payload.userRole,
        })
        .then((res) => {
          commit("set_loading", false);
          // Vue.$toast('Your data submited successfully, Thank you!', {
          //     timeout: 5000
          // })
          successToast("Your data submited successfully, Thank you!");
        })
        .catch((error) => {
          error.message;
          if (error.response.status == 422 || error.response.status == 409) {
            // Vue.$toast.warning(error.response.data.message, {
            //     timeout: 5000
            // })
            warningToast(error.response.data.message);
            commit("set_loading", false);
          }
        });
    },

    confirm_email_action: ({ commit }, payload) => {
      axios
        .post(`${BaseURL}api/ResetPassword`, {
          email: payload.email,
        })
        .then((res) => {
          if (res.status == 200) {
            // Vue.$toast.success('A recovery mail has been sent to your email.', {
            //     timeout: 5000
            // })
            successToast("A recovery mail has been sent to your email.");
            router.push({ path: "/login" });
          }
        })
        .catch((error) => {
          if (error.response.status == 409) {
            // Vue.$toast.warning('The email you entered dosen\'t exit!', {
            //     timeout: 5000
            // })
            warningToast("The email you entered dosen't exit!");
          }
        });
    },

    reset_password_action: ({ commit }, payload) => {
      axios
        .post(
          `${BaseURL}api/ResetPasswordStepTwo`,
          {
            token: payload.token,
            password: payload.password,
            confirmation_password: payload.confirmPassword,
          },
          {
            headers: {
              XTenant: payload.tenantId,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // Vue.$toast.success('Password reseted successfully! Now you can login with new password.', {
            //     timeout: 5000
            // })
            successToast(
              "Password reseted successfully! Now you can login with new password."
            );
            router.push({ path: "/login" });
          }
        })
        .catch((err) => {
          err.message;
          // Vue.$toast.error('Something went wrong!', {
          //     timeout: 5000
          // })
          errorToast("Something went wrong!");
        });
    },

    confirm_invitation_action: ({ commit }, payload) => {
      axios
        .post(`${BaseURL}api/ConfirmMemberPassword`, {
          email: payload.email,
          password: payload.password,
          tenant_id: payload.tenantId,
        })
        .then((res) => {
          successToast("Password set successfully!");
          router.push({ path: "/login" });
        })
        .catch((err) => {
          err.message;
        });
    },
  },
  modules: {},
};
